:root {
  --fontColor: #646567;
  --fontColorLight: #64656730;
  --primary: #0b1e2d;
  --primaryLight: #0b1e2d40;
  --backgroundColor: #fafafa;
  --backgroundCard: #e6e8ea;
  --danger: crimson;
  --success: #2ecc71;
}
