@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-Bold.eot");
  src: url("HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-Bold.woff2") format("woff2"),
    url("HelveticaNeue-Bold.woff") format("woff"),
    url("HelveticaNeue-Bold.ttf") format("truetype"),
    url("HelveticaNeue-Bold.svg#HelveticaNeue-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-CondensedBlack.eot");
  src: url("HelveticaNeue-CondensedBlack.eot?#iefix")
      format("embedded-opentype"),
    url("HelveticaNeue-CondensedBlack.woff2") format("woff2"),
    url("HelveticaNeue-CondensedBlack.woff") format("woff"),
    url("HelveticaNeue-CondensedBlack.ttf") format("truetype"),
    url("HelveticaNeue-CondensedBlack.svg#HelveticaNeue-CondensedBlack")
      format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue.eot");
  src: url("HelveticaNeue.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue.woff2") format("woff2"),
    url("HelveticaNeue.woff") format("woff"),
    url("HelveticaNeue.ttf") format("truetype"),
    url("HelveticaNeue.svg#HelveticaNeue") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-LightItalic.eot");
  src: url("HelveticaNeue-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-LightItalic.woff2") format("woff2"),
    url("HelveticaNeue-LightItalic.woff") format("woff"),
    url("HelveticaNeue-LightItalic.ttf") format("truetype"),
    url("HelveticaNeue-LightItalic.svg#HelveticaNeue-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-Light.eot");
  src: url("HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-Light.woff2") format("woff2"),
    url("HelveticaNeue-Light.woff") format("woff"),
    url("HelveticaNeue-Light.ttf") format("truetype"),
    url("HelveticaNeue-Light.svg#HelveticaNeue-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-Italic.eot");
  src: url("HelveticaNeue-Italic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-Italic.woff2") format("woff2"),
    url("HelveticaNeue-Italic.woff") format("woff"),
    url("HelveticaNeue-Italic.ttf") format("truetype"),
    url("HelveticaNeue-Italic.svg#HelveticaNeue-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-MediumItalic.eot");
  src: url("HelveticaNeue-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-MediumItalic.woff2") format("woff2"),
    url("HelveticaNeue-MediumItalic.woff") format("woff"),
    url("HelveticaNeue-MediumItalic.ttf") format("truetype"),
    url("HelveticaNeue-MediumItalic.svg#HelveticaNeue-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-Medium.eot");
  src: url("HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-Medium.woff2") format("woff2"),
    url("HelveticaNeue-Medium.woff") format("woff"),
    url("HelveticaNeue-Medium.ttf") format("truetype"),
    url("HelveticaNeue-Medium.svg#HelveticaNeue-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-UltraLight.eot");
  src: url("HelveticaNeue-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-UltraLight.woff2") format("woff2"),
    url("HelveticaNeue-UltraLight.woff") format("woff"),
    url("HelveticaNeue-UltraLight.ttf") format("truetype"),
    url("HelveticaNeue-UltraLight.svg#HelveticaNeue-UltraLight") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-ThinItalic.eot");
  src: url("HelveticaNeue-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-ThinItalic.woff2") format("woff2"),
    url("HelveticaNeue-ThinItalic.woff") format("woff"),
    url("HelveticaNeue-ThinItalic.ttf") format("truetype"),
    url("HelveticaNeue-ThinItalic.svg#HelveticaNeue-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-UltraLightItalic.eot");
  src: url("HelveticaNeue-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("HelveticaNeue-UltraLightItalic.woff2") format("woff2"),
    url("HelveticaNeue-UltraLightItalic.woff") format("woff"),
    url("HelveticaNeue-UltraLightItalic.ttf") format("truetype"),
    url("HelveticaNeue-UltraLightItalic.svg#HelveticaNeue-UltraLightItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue-Thin.eot");
  src: url("HelveticaNeue-Thin.eot?#iefix") format("embedded-opentype"),
    url("HelveticaNeue-Thin.woff2") format("woff2"),
    url("HelveticaNeue-Thin.woff") format("woff"),
    url("HelveticaNeue-Thin.ttf") format("truetype"),
    url("HelveticaNeue-Thin.svg#HelveticaNeue-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
