@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";
@import "./font/stylesheet.css";

body {
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fontColor);
  overflow: hidden;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.full-width {
  width: 100%;
}
.full-height {
  min-height: 100vh;
}
.no-overflow {
  overflow: hidden;
}
.wrap {
  flex-wrap: wrap;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mt-05 {
  margin-bottom: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-4 {
  margin-right: 4rem;
}

.trim-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
}
.trim-text-no-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h1,
h2,
h3 {
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary);
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;

  // margin-bottom: 1rem;
}

a {
  color: var(--primary);
  text-decoration: none;
}

.small-text {
  font-size: 0.8rem;
}

.content {
  height: calc(100vh - 80px);
  overflow-y: auto;
  scrollbar-width: auto !important;
  scrollbar-color: var(--primary) var(--fontColorLight) !important;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary) !important;
  }
  &::-webkit-scrollbar-track {
    background: var(--fontColorLight) !important;
  }

  .content-container {
    // flex-grow: 1;
    // min-width: 1600px;
    min-height: calc(100vh - 130px);
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem 2rem;
    // height: 100%;
    @media screen and (max-width: 1600px) {
      padding: 2rem 2rem;
    }

    // background-color: aqua;
  }
}

.skeleton-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(11, 30, 45, 0) 0%,
    rgba(11, 30, 45, 0.9) 50%,
    rgba(11, 30, 45, 0) 100%
  );
  background-size: 200% 100%;
  animation: skeleton_loading 1.5s infinite;
}

@keyframes skeleton_loading {
  0% {
    background-position: 0 0;
  } /* Inizia dalla posizione iniziale */
  100% {
    background-position: 100% 0;
  } /* Scorrimento fino alla fine */
}

.custom-datepicker::placeholder {
  color: var(--fontColor);
}

.react-datepicker__close-icon::after {
  background-color: var(--fontColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary) !important;
  color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primaryLight) !important;
  color: #fff;
}
